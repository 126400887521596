import { useTranslation } from "react-i18next";
import { UnicefBanner, LanguagePicker } from "../components";

export const Donors2 = () => {
	const { t } = useTranslation();

	return (
		<main>
			<LanguagePicker />
			<section className="grid grid-cols-1 lg:grid-cols-12">
				<div
					className="col-span-12 lg:col-span-4 w-full bg-cover bg-right-bottom min-h-[400px] order-2 lg:order-[unset] lg:h-[calc(100%+1px)])]"
					style={{ backgroundImage: "url('assets/1.png')" }}
				></div>
				<div className="col-span-12 px-4 lg:px-0 lg:col-span-4 w-full flex justify-center flex-col items-center">
					<UnicefBanner
						type="small"
						isAbsolute={false}
						withTransition={true}
						transitionDirection="top"
					/>
					<h1 className="uppercase text-orange-500 text-4xl lg:text-6xl text-center mt-10 lg:mt-20">
						{t("Nutrition now")}
					</h1>
					<h4 className="mt-5 px-5 text-blue text-center text-sm lg:text-xl mb-20">
						{t(
							"RE-IMAGINING NUTRITION FOR EVERY CHILD IN TAJIKISTAN"
						)}
					</h4>
				</div>
				<div className="col-span-12 lg:col-span-4 bg-red-500 "></div>
			</section>
			<section>
				<h2 className="text-xl uppercase lg:text-4xl text-center my-10 text-orange-500">
					{t("Top-3 figures for children under five")}:
				</h2>
			</section>
			<section className="grid grid-cols-1 lg:grid-cols-12 text-white">
				<div className="col-span-4 relative">
					<div className="w-0 h-0 border-t-[15px] border-l-[15px] border-r-[15px] border-l-transparent border-r-transparent border-t-white border-solid absolute top-0 left-1/2 transform -translate-x-1/2"></div>
					<div
						className="h-96 bg-center bg-cover px-10 py-5 flex flex-col justify-between"
						style={{
							backgroundImage:
								"url('assets/donors-stunting.jpg')",
						}}
					>
						<div className="text-lg lg:text-2xl font-semibold uppercase">
							{t("STUNTING")}
						</div>
						<div className="font-bold text-3xl lg:text-7xl">
							18%
						</div>
					</div>
					<div className="bg-yellow-500 lg:h-28 pt-2 pb-5 px-4 lg:px-5 text-center">
						{t(
							"Are stunted, or their height is too low for their age."
						)}
					</div>
				</div>
				<div className="col-span-4 relative">
					<div className="w-0 h-0 border-t-[15px] border-l-[15px] border-r-[15px] border-l-transparent border-r-transparent border-t-white border-solid absolute top-0 left-1/2 transform -translate-x-1/2"></div>
					<div
						className="h-96 bg-center bg-cover px-10 py-5 flex flex-col justify-between"
						style={{
							backgroundImage: "url('assets/donors-wasting.jpg')",
						}}
					>
						<div className="text-lg lg:text-2xl font-semibold uppercase">
							{t("WASTING")}
						</div>
						<div className="font-bold text-3xl lg:text-7xl">6%</div>
					</div>
					<div className="bg-orange-500 lg:h-28 pt-2 pb-5 px-4 lg:px-5 text-center">
						{t(
							`wasted or too thin for their height. They are 11 times as likely to die from common childhood illnesses such as pneumonia than a healthy child.`
						)}
					</div>
				</div>
				<div className="col-span-4 relative">
					<div className="w-0 h-0 border-t-[15px] border-l-[15px] border-r-[15px] border-l-transparent border-r-transparent border-t-white border-solid absolute top-0 left-1/2 transform -translate-x-1/2"></div>
					<div
						className="h-96 bg-center bg-cover px-10 py-5 flex flex-col justify-between"
						style={{
							backgroundImage:
								"url('assets/donors-immunity.jpg')",
						}}
					>
						<div className="text-lg lg:text-2xl font-semibold uppercase">
							{t("LOWER IMMUNITY")}
						</div>
						<div className="font-bold text-3xl lg:text-7xl">
							37%
						</div>
					</div>
					<div className="bg-red-500 lg:h-28 pt-2 pb-5 px-4 lg:px-5 text-center">
						{t(
							"are vitamin A deficient, leading to lower immunity and eyesight"
						)}
					</div>
				</div>
			</section>
			<section className="container px-4 mx-auto my-10">
				<h3 className="text-xl lg:text-3xl text-blue">
					{t(
						"In response UNICEF’s Nutrition programming is focused on prevention first, while and providing early and quality treatment as soon as it’s required."
					)}
				</h3>
			</section>
			<section className="text-white bg-blue p-5 text-center uppercase text-xl lg:text-4xl">
				<h2>{t("3 areas of our results achieved for 2022:")}</h2>
			</section>
			<section className="grid grid-cols-1 lg:grid-cols-12">
				<div className="relative px-8 pt-4 pb-8 col-span-4 bg-red-500">
					<div className="w-0 h-0 border-t-[15px] border-l-[15px] border-r-[15px] border-l-transparent border-r-transparent border-t-blue border-solid absolute top-0 left-1/2 transform -translate-x-1/2"></div>
					<img
						className="mx-auto w-[150px] lg:w-[300px]"
						src="assets/signs.svg"
						alt="signs"
						width={300}
					/>
					<div className="px-5 border-l border-white text-white font-bold h-max text-xs lg:text-md">
						{t(
							"PROVIDED TREATMENT FOR 50 000 CHILDREN AFFECTED BY WASTING."
						)}
					</div>
				</div>
				<div className="relative px-8 pt-4 pb-8 col-span-4 bg-orange-500">
					<div className="w-0 h-0 border-t-[15px] border-l-[15px] border-r-[15px] border-l-transparent border-r-transparent border-t-red-500 lg:border-t-blue border-solid absolute top-0 left-1/2 transform -translate-x-1/2"></div>
					<img
						className="mx-auto w-[150px] lg:w-[300px]"
						src="assets/tajikistan.svg"
						alt="signs"
						width={300}
					/>
					<div className="px-5 border-l border-white text-white font-bold h-max text-xs lg:text-md">
						{t(
							"STRENGTHENED THE CAPACITY OF THE GOVERNMENT OF TAJIKISTAN AND THE MINISTRY OF HEALTH IN IMPLEMENTING OF THE UNIVERSAL SALT IODIZATION PROGRAMME IN THE COUNTRY, AND PROVISION OF MICRO NUTRIENT SUPPLEMENTATION."
						)}
					</div>
				</div>
				<div className="relative px-8 pt-4 pb-8 col-span-4 bg-yellow-500">
					<div className="w-0 h-0 border-t-[14px] border-l-[15px] border-r-[15px] border-l-transparent border-r-transparent border-t-orange-500 lg:border-t-blue border-solid absolute top-0 left-1/2 transform -translate-x-1/2"></div>
					<img
						className="mx-auto w-[150px] lg:w-[300px]"
						src="assets/baby-bottle.svg"
						alt="signs"
						width={300}
					/>
					<div className="px-5 border-l text-white font-bold border-white h-max text-xs lg:text-md">
						{t(
							"PROMOTED BEHAVIORAL CHANGE IN INFANT AND YOUNG CHILD FEEDING PRACTICES."
						)}
					</div>
				</div>
			</section>
		</main>
	);
};
