import React from "react";
import { Tween } from "react-gsap";
import { DELAY_START } from "../../constants";
import { useTranslation } from "react-i18next";

export const Heading = (
	props: React.PropsWithChildren | { position: "left" | "right" }
) => {
	const { t } = useTranslation();

	return (
		<div className="grid grid-cols-1 md:grid-cols-2 my-5 md:my-0">
			<div className="hidden md:block"></div>

			<div className="w-full relative px-3 md:p-0">
				<Tween
					from={{
						opacity: 0,
						delay: DELAY_START,
					}}
					to={{
						opacity: 1,
						delay: DELAY_START,
					}}
				>
					<h1 className="uppercase mb-2 md:mb-10 lg:absolute top-[-75px] text-4xl md:text-6xl font-bold text-orange-400 bg-white md:py-2 pl-3 pr-2">
						{t("Nutrition now")}
					</h1>
					<h2 className="w-full p-4 bg-blue uppercase text-xl md:text-2xl font-bold text-white">
						{t(
							"RE-IMAGINING NUTRITION FOR EVERY CHILD IN TAJIKISTAN"
						)}
					</h2>
				</Tween>
			</div>
		</div>
	);
};
