import React, { useEffect, useState } from "react";
import cl from "classnames";
import { Transition } from "react-transition-group";
import "./UnicefBanner.css";
import { TIMEOUT } from "../../constants";

interface IUnicefBanner {
	type?: string;
	isAbsolute?: boolean;
	withTransition?: boolean;
	transitionDirection?: "top" | "bottom";
}

const TRANSITION_DIRECTION = {
	top: "-translate-y-full",
	bottom: "translate-y-full",
};

export const UnicefBanner = ({
	type = "normal",
	isAbsolute,
	withTransition,
	transitionDirection = "bottom",
}: IUnicefBanner) => {
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		setIsVisible(true);
	}, []);

	if (withTransition) {
		return (
			<Transition
				in={isVisible}
				timeout={TIMEOUT}
				mountOnEnter
				unmountOnExit
			>
				{(state: any) => (
					<div
						className={cl(
							{ "unicef-banner--absolute": isAbsolute !== false },
							type === "normal"
								? "unicef-logo"
								: "unicef-logo--small",
							"transition-transform duration-500 transform",
							state === "entered"
								? "translate-y-0"
								: TRANSITION_DIRECTION[transitionDirection]
						)}
					>
						<img
							className="w-[80px] md:w-full"
							src="assets/unicef-logo.png"
							alt="unicef-logo"
						/>
					</div>
				)}
			</Transition>
		);
	}
	return (
		<div
			className={cl(
				{ "unicef-banner--absolute": isAbsolute !== false },
				type === "normal" ? "unicef-logo" : "unicef-logo--small",
				"md:right-[40px]"
			)}
		>
			<img src="assets/unicef-logo.png" alt="unicef-logo" />
		</div>
	);
};

export default UnicefBanner;
