import { WindowEffect } from "../components";
import { WindowEffectContextProvider } from "../contexts";
import { Donors2 } from "./donors2";

export const DonatorsPage = () => {
	return (
		<WindowEffectContextProvider>
			<div className="container mx-auto w-full max-w-full min-h-screen">
				<WindowEffect />
				<Donors2 />
			</div>
		</WindowEffectContextProvider>
	);
};
