import React from "react";
import "./RippleButton.css";
import cl from "classnames";

interface IRippleButton {
	className?: string;
	children?: React.ReactChild;
	title?: string;
	onClick?: () => void;
}

export const RippleButton = ({
	title,
	children,
	className,
	onClick,
}: IRippleButton) => {
	return (
		<button className={cl("button-pulse", className)} onClick={onClick}>
			{title || children}
		</button>
	);
};
