import React, { createContext, useState } from "react";

interface IWindowEffectContext {
	isEffectFinished: boolean;
	updateData: (newData: boolean) => void;
}

const initialContextValue: IWindowEffectContext = {
	isEffectFinished: false,
	updateData: () => {},
};

export const WindowEffectContext = createContext(initialContextValue);

export const WindowEffectContextProvider = ({
	children,
}: React.PropsWithChildren) => {
	const [isEffectFinished, setIsEffectFinished] = useState(false);

	const updateData = (newData: boolean) => {
		setIsEffectFinished(newData);
	};

	const contextValue: IWindowEffectContext = {
		isEffectFinished,
		updateData,
	};

	return (
		<WindowEffectContext.Provider value={contextValue}>
			{children}
		</WindowEffectContext.Provider>
	);
};
