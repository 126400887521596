import React, { useState, useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./Main.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { ImageContext } from "../../contexts";
import { IImage } from "../../types";

interface SlideContentProp {
	src: string;
	alt: string;
	desc: string;
	percentage?: string;
	heading?: string;
	sign?: string;
}

const SlideContent = ({
	src,
	alt,
	percentage,
	desc,
	heading,
	sign,
}: SlideContentProp) => {
	let headingText;
	if (percentage) {
		headingText = percentage + "%";
	} else if (heading) {
		headingText = heading;
	}
	const { t } = useTranslation();

	return (
		<div className="flex flex-wrap justify-center items-center lg:flex-nowrap shadow-sm w-full absolute mt-20 bg-secondary">
			<LazyLoadImage
				alt={alt}
				className="col-span-8 max-h-[350px] lg:max-h-[650px] w-auto"
				src={src}
			/>
			<div className="w-full flex flex-col flex-end justify-end items-start col-span-4 bg-secondary text-white py-10 px-5 font-bold">
				{(percentage || heading) && (
					<h2 className="text-5xl md:text-[6rem] mb-4 mx-auto md:mx-0">
						{headingText}
						{sign && (
							<span className="text-4xl md:text-8xl">{sign}</span>
						)}
					</h2>
				)}
				<div className="text-lg text-center md:text-left md:text-2xl">
					{t(desc)}
				</div>
			</div>
		</div>
	);
};

export const StatSection: React.FC = () => {
	const [activeSlide, setActiveSlide] = useState(0);
	const slidesRef = useRef<HTMLDivElement>(null);
	const carouselIntervalRef = useRef<NodeJS.Timeout | null>(null);
	const slides = slidesRef?.current?.children;
	const { images } = useContext(ImageContext);
	const [imagesObj, setImagesObj]: any = useState({});

	useEffect(() => {
		if (slides) {
			gsap.set(slides, {
				opacity: 0,
				visibility: "hidden",
			});
			gsap.from(slides, {
				opacity: 1,
				visibility: "visible",
			});
			gsap.to(slides[activeSlide], {
				opacity: 1,
				duration: 0.5,
				visibility: "visible",
				transition: "all 1s ease-in-out",
				x: "0%",
			});
		}
	}, [activeSlide, slides]);

	useEffect(() => {
		startCarousel();

		return () => {
			stopCarousel();
		};
	}, []);

	useEffect(() => {
		images.forEach((image: IImage) => {
			imagesObj[image.place] = image.imageUrl;
		});
		setImagesObj(imagesObj);
	}, [images, imagesObj]);

	const startCarousel = () => {
		carouselIntervalRef.current = setInterval(() => {
			setActiveSlide((prevSlide) => (prevSlide + 1) % totalSlides);
		}, 5000); // Adjust the interval time as needed (in milliseconds)
	};

	const stopCarousel = () => {
		if (carouselIntervalRef.current) {
			clearInterval(carouselIntervalRef.current);
			carouselIntervalRef.current = null;
		}
	};

	const handleSlideClick = (slideIndex: number) => {
		if (slides) {
			gsap.set(slides, {
				opacity: 0,
				visibility: "hidden",
			});
			setActiveSlide(slideIndex);
		}
	};

	const totalSlides = 8; // Update the total number of slides

	return (
		<section>
			<div className="slide-container">
				<div
					className="slides-wrapper relative h-[600px] md:h-[750px] w-full"
					ref={slidesRef}
				>
					<div
						className={`slide ${activeSlide === 0 ? "active" : ""}`}
					>
						<SlideContent
							src={imagesObj["slider_1"]}
							alt="slide-1"
							percentage="62"
							desc="of children are put to breast within an hour of birth"
						/>
					</div>
					<div
						className={`slide ${activeSlide === 1 ? "active" : ""}`}
					>
						<SlideContent
							src={imagesObj["slider_2"]}
							alt="slide-2"
							percentage="36"
							desc="of children under 6 months are exclusively breastfed"
						/>
					</div>
					<div
						className={`slide ${activeSlide === 2 ? "active" : ""}`}
					>
						<SlideContent
							src={imagesObj["slider_3"]}
							alt="slide-3"
							percentage="29"
							desc="of children 6-24 months are fed minimum diverse diets"
						/>
					</div>
					<div
						className={`slide ${activeSlide === 3 ? "active" : ""}`}
					>
						<SlideContent
							src={imagesObj["slider_4"]}
							alt="slide-4"
							percentage="38"
							desc="of children continued to be breastfed at 24 months"
						/>
					</div>
					<div
						className={`slide ${activeSlide === 4 ? "active" : ""}`}
					>
						<SlideContent
							src={imagesObj["slider_5"]}
							alt="slide-5"
							percentage="9"
							desc="of children 6-24 months were fed minimum acceptable diets in terms of dietary diversity and meal frequency"
						/>
					</div>
					<div
						className={`slide ${activeSlide === 5 ? "active" : ""}`}
					>
						<SlideContent
							src={imagesObj["slider_6"]}
							alt="slide-6"
							heading="48"
							sign="mcg/l"
							desc="median urinary iodine concentration among children under 5 years, indicating insufficient iodine intake"
						/>
					</div>
					<div
						className={`slide ${activeSlide === 6 ? "active" : ""}`}
					>
						<SlideContent
							src={imagesObj["slider_7"]}
							alt="slide-7"
							percentage="13"
							desc="of salt is adequately iodized (15-40 mg of iodine per kg of salt)"
						/>
					</div>
					<div
						className={`slide ${activeSlide === 7 ? "active" : ""}`}
					>
						<SlideContent
							src={imagesObj["slider_8"]}
							alt="slide-8"
							percentage="8.7"
							desc="of children are estimated to have low weight at birth (less than 2.5 kg at birth)"
						/>
					</div>
				</div>
				<div className="dots-container">
					{Array.from({ length: totalSlides }).map((_, index) => (
						<div
							key={index}
							className={`dot ${
								index === activeSlide ? "active" : ""
							} p-2`}
							onClick={() => handleSlideClick(index)}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default StatSection;
