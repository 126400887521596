import { useContext } from "react";
import { Heading, Header, Footer, SplashScreen } from "../";
import "./Main.css";
import { Tween, ScrollTrigger as ScrollTriggerLib } from "react-gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { gsap } from "gsap";
import {
	NumbersSection,
	PartnerWithUsSection,
	StatSection,
	ChartSection,
	VideoSection,
} from "./";
import { useTranslation } from "react-i18next";
import { ImageContext } from "../../contexts";

gsap.registerPlugin(ScrollTrigger);

const AchievedSection = () => {
	const { t } = useTranslation();

	return (
		<section>
			<h2 className="text-4xl text-center uppercase text-blue">
				{t("4 Areas of UNICEF Tajikistan Nutrition Programme")}
			</h2>
			<div className="grid grid-cols-1 md:grid-cols-2 md:flex-row mt-20">
				<Tween
					from={{
						// y: "-100%",
						ease: "power2.Out",
						opacity: 0,
					}}
					to={{
						// y: "0",
						opacity: 1,
						ease: "power2.Out",
					}}
				>
					<div className="col-span-1 font-bold w-full bg-orange-500 text-white py-5 px-5 text-center text-lg md:text-xl">
						<a
							href="https://www.unicef.org/tajikistan/nutrition"
							target="_blank"
							rel="noreferrer"
							className="hover:underline"
						>
							<span className="flex flex-col justify-center items-center">
								<img
									src="assets/meal.png"
									alt="meal icon"
									width={200}
									loading="lazy"
								/>
								{t("Nutrition governance strengthening")}
							</span>
						</a>
					</div>
				</Tween>
				<Tween
					from={{
						ease: "power2.Out",
						opacity: 0,
					}}
					to={{
						opacity: 1,
						ease: "power2.Out",
					}}
				>
					<div className="col-span-1 font-bold w-full bg-blue text-white py-5 px-5 text-center text-lg md:text-xl">
						<a
							href="https://www.unicef.org/tajikistan/nutrition"
							target="_blank"
							rel="noreferrer"
							className="hover:underline"
						>
							<span className="flex flex-col justify-center items-center">
								<img
									src="assets/salt.png"
									alt="salt icon"
									width={200}
									loading="lazy"
								/>
								{t(
									"Maternal nutrition and young child’s diets"
								)}
							</span>
						</a>
					</div>
				</Tween>
				<Tween
					from={{
						ease: "power2.Out",
						opacity: 0,
					}}
					to={{
						opacity: 1,
						ease: "power2.Out",
					}}
				>
					<div className="col-span-1 font-bold w-full bg-yellow-400 text-white py-7 px-7 text-center text-lg md:text-xl">
						<a
							href="https://www.unicef.org/tajikistan/nutrition"
							target="_blank"
							rel="noreferrer"
							className="hover:underline"
						>
							<span className="flex flex-col justify-center items-center">
								<img
									src="assets/fruits.png"
									alt="salt icon"
									width={200}
									loading="lazy"
								/>
								{t(
									"Care for wasted children through integrated management of acute malnutrition"
								)}
							</span>
						</a>
					</div>
				</Tween>
				<Tween
					from={{
						ease: "power2.Out",
						opacity: 0,
					}}
					to={{
						opacity: 1,
						ease: "power2.Out",
					}}
				>
					<div className="col-span-1 font-bold w-full bg-red-500 text-white py-7 px-7 text-center text-lg md:text-xl hover:shadow-lg hover:cursor-pointer">
						<a
							href="https://www.unicef.org/tajikistan/nutrition"
							target="_blank"
							rel="noreferrer"
							className="hover:underline"
						>
							<span className="flex flex-col justify-center items-center">
								<img
									src="assets/healthy-food.png"
									alt="salt icon"
									width={150}
									loading="lazy"
									className="py-5"
								/>
								{t("Prevention of micronutrient deficiencies")}
							</span>
						</a>
					</div>
				</Tween>
			</div>
		</section>
	);
};

export const Main = () => {
	const { loading } = useContext(ImageContext);
	if (loading) return <SplashScreen />;
	return (
		<ScrollTriggerLib start="-500px center">
			<Header />
			<main>
				<Heading />
				<NumbersSection />
				<ChartSection />
				<AchievedSection />
				<StatSection />
				<VideoSection />
				<PartnerWithUsSection />
			</main>
			<Footer />
		</ScrollTriggerLib>
	);
};
