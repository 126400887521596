import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MainPage, DonatorsPage } from "./pages";

// const BASENAME = packageJson?.homepage || undefined;

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<MainPage />} />
				<Route path="/donors" element={<DonatorsPage />} />
			</Routes>
		</Router>
	);
}

export default App;
