import { useTranslation } from "react-i18next";
import {
	ResponsiveContainer,
	LineChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	Line,
} from "recharts";

const DATA = [
	{
		name: 1999,
		wasting: 13.6,
		stunting: 37.3,
	},
	{
		name: 2000,
		wasting: 7.8,
		stunting: 35.9,
	},
	{
		name: 2001,
		wasting: 17.3,
		stunting: 36.2,
	},
	{
		name: 2002,
		wasting: 4.94,
		stunting: 30.9,
	},
	{
		name: 2003,
		wasting: 4.3,
		stunting: 35,
	},
	{
		name: 2004,
		wasting: 6.9,
		stunting: 30.42,
	},
	{
		name: 2005,
		wasting: 7.2,
		stunting: 27,
		underweight: 17.4,
	},
	{
		name: 2006,
		wasting: 7,
		stunting: 20.75,
		underweight: 27,
	},
	{
		name: 2007,
		wasting: 5.3,
		stunting: 33.9,
		underweight: 17.6,
	},
	{
		name: 2009,
		wasting: 4.5,
		stunting: 28.9,
		underweight: 8.4,
	},
	{
		name: 2012,
		wasting: 9.9,
		stunting: 26.2,
		underweight: 12.1,
	},
	{
		name: 2016,
		wasting: 2.8,
		stunting: 20.9,
		underweight: 6.2,
	},
	{
		name: 2017,
		wasting: 5.6,
		stunting: 17.4,
		underweight: 7.6,
	},
];

export const ChartSection = () => {
	const { t } = useTranslation();
	return (
		<div className="lg:container mx-auto h-[200px] w-full lg:h-[500px] my-20">
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					data={DATA}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip
						formatter={(value: any, name: string) => [
							value,
							t(name),
						]}
					/>
					<Legend
						formatter={(value) => t(value)}
						verticalAlign="top"
						align="right"
						height={36}
					/>
					<Line dataKey="stunting" stroke="blue" />
					<Line dataKey="underweight" stroke="green" />
					<Line dataKey="wasting" stroke="red" />
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
};
