// SplashScreen.js

export const SplashScreen = () => {
	return (
		<div className="flex justify-center items-center h-screen bg-gray-100">
			<div className="flex flex-col items-center">
				<svg
					className="animate-spin h-10 w-10 text-gray-800 mb-4"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
				>
					<circle
						className="opacity-25"
						cx="12"
						cy="12"
						r="10"
						stroke="currentColor"
						strokeWidth="4"
					></circle>
					<path
						className="opacity-75"
						fill="currentColor"
						d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0117.709 5H20c0 6.627-5.373 12-12 12v-2.009zm9.291 2A8.001 8.001 0 015 19.291V22c6.627 0 12-5.373 12-12h-2.009z"
					></path>
				</svg>
				<p className="text-gray-800">Loading...</p>
			</div>
		</div>
	);
};
