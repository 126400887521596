// src/utils/api.ts
interface ApiResponse<T> {
	data: T;
}

export async function fetchData<T>(url: string): Promise<T> {
	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(
				`Error: ${response.status} - ${response.statusText}`
			);
		}

		const responseData: ApiResponse<T> & T = await response.json();
		return responseData.data || responseData;
	} catch (error) {
		console.error("Error fetching data:", error);
		throw error; // You can handle the error appropriately in your application
	}
}
