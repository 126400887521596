import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { API_URL } from "./constants";

i18n.use(HttpBackend).init({
	backend: {
		loadPath: API_URL + "/language/format/{{lng}}",
	},
	fallbackLng: "en",
	interpolation: {
		escapeValue: false, // By default, values are escaped to prevent XSS attacks. Set it to false for React.
	},
});

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
