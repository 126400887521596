import { Tween } from "react-gsap";
import { DELAY_START, DELAY_INCREASE } from "../../constants";
import { useTranslation } from "react-i18next";

export const PartnerWithUsSection = () => {
	const { t } = useTranslation();

	return (
		<section className="mt-10">
			<div className="bg-blue text-white p-10 font-bold shadow-lg">
				<h2 className="text-2xl md:text-4xl text-center">
					{t("Partner with us and together we can")}
				</h2>
			</div>
			<div className="flex flex-wrap md:grid md:grid-flow-col items-center text-xl mt-10 uppercase font-bold justify-center text-center">
				<Tween
					from={{
						opacity: 0,
						delay: DELAY_START,
					}}
					to={{
						opacity: 1,
						delay: DELAY_START,
					}}
				>
					<div className="col-span-4 bg-orange-500 shadow-xl w-full text-white p-3 h-full flex items-center justify-center text-sm">
						{t("Protect and promote diets")}
					</div>
				</Tween>
				<Tween
					from={{
						opacity: 0,
						delay: DELAY_START + DELAY_INCREASE,
					}}
					to={{
						opacity: 1,
						delay: DELAY_START + DELAY_INCREASE,
					}}
				>
					<p className="col-span-4 bg-blue shadow-xl text-white p-5 h-full text-sm flex items-center">
						{t(
							"services and practices that support optimal nutrition"
						)}
					</p>
				</Tween>
				<Tween
					from={{
						opacity: 0,
						delay: DELAY_START + DELAY_INCREASE * 2,
					}}
					to={{
						opacity: 1,
						delay: DELAY_START + DELAY_INCREASE * 2,
					}}
				>
					<p className="col-span-4 bg-yellow-500 shadow-xl text-white p-5 h-full text-sm flex items-center">
						{t(
							"growth and development for all children, adolescents and women in Tajikistan so that they realize their right to nutrition"
						)}
					</p>
				</Tween>
			</div>
		</section>
	);
};
