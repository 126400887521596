import React, { useMemo } from "react";
import "./Header.css"; // Import your CSS file for header styles if needed
import { UnicefBanner, LanguagePicker } from "../";
import { useContext } from "react";
import { ImageContext } from "../../contexts";

export const Header = (props: React.PropsWithChildren) => {
	const { images } = useContext(ImageContext);
	const image = useMemo(
		() => images && images.find((image) => image.place === "header_bg"),
		[images]
	);

	return (
		<header
			className="relative header"
			style={{
				backgroundImage: `url(${
					image?.imageUrl || '"../../../public/assets/bg.webp"'
				})`,
			}}
		>
			<LanguagePicker />
			<UnicefBanner transitionDirection="top" withTransition />
			{props.children}
		</header>
	);
};

export default Header;
