import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const GlobeIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		stroke-width="1.5"
		stroke="currentColor"
		className="w-6 h-6"
	>
		<path
			stroke-linecap="round"
			stroke-linejoin="round"
			d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
		/>
	</svg>
);

type LanguagePickerProps = {
	selectedLanguage?: string;
	onChangeLanguage?: (language: string) => void;
};

export const LanguagePicker: React.FC<LanguagePickerProps> = () => {
	const [selectedLanguage, setSelectedLanguage] = useState<string>("en");
	const { i18n } = useTranslation();
	useEffect(() => {
		const language = localStorage.getItem("language");
		if (language) {
			setSelectedLanguage(language);
			i18n.changeLanguage(language);
		}
	}, [i18n]);

	const languages = [
		{ code: "en", name: "English", icon: "🇺🇸" },
		{ code: "ru", name: "Русский", icon: "🇷🇺" },
		{ code: "tj", name: "Точики", icon: "🇹🇯" },
	];

	const handleChangeLanduage = (selectedLanguage: string) => {
		setSelectedLanguage(selectedLanguage);
		i18n.changeLanguage(selectedLanguage);
		localStorage.setItem("language", selectedLanguage);
	};

	return (
		<div className="flex items-center absolute top-2 left-5">
			<div className="relative">
				<select
					value={selectedLanguage}
					onChange={(e) => handleChangeLanduage(e.target.value)}
					className="block appearance-none py-2 pl-3 pr-8 cursor-pointer"
				>
					{languages.map((language) => (
						<option key={language.code} value={language.code}>
							{language.icon} {language.name}{" "}
						</option>
					))}
				</select>
				<div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
					<GlobeIcon />
				</div>
			</div>
		</div>
	);
};

export default LanguagePicker;
