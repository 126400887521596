import React, { useState, useEffect, useContext } from "react";
import cl from "classnames";
import { WindowEffectContext } from "../../contexts";
import { UnicefBanner } from "../../components";
import "./WindowEffect.css";
import { useTranslation } from "react-i18next";

const TIMEOUT = 500;

export const WindowEffect = () => {
	const [isRevealed, setIsRevealed] = useState<boolean>(false);
	const [displayNone, setDisplayNone] = useState(false);
	const { updateData } = useContext(WindowEffectContext);
	const { t } = useTranslation();

	useEffect(() => {
		if (isRevealed) {
			updateData(true);
			setTimeout(() => setDisplayNone(true), TIMEOUT * 2);
		}
	}, [isRevealed, updateData]);

	if (displayNone) {
		return null;
	}

	return (
		<div className={cl("intro", { revealed: isRevealed })}>
			<button
				className="intro-button animate-bounce uppercase"
				onClick={() => setIsRevealed(true)}
			>
				{t("open")}
			</button>
			<div className="intro-left">
				<img
					src="assets/into-left.png"
					className="intro-left-img"
					alt="intro left"
				/>
				<div className="intro-splitter"></div>
			</div>
			<div className="intro-right">
				<img
					src="assets/into-right.png"
					className="intro-right-img"
					alt="intro right"
				/>
			</div>
			<div className="window-effect--unicef-logo absolute bottom-0 w-full flex justify-center ">
				<UnicefBanner
					type="small"
					isAbsolute={false}
					withTransition={true}
				/>
			</div>
		</div>
	);
};
