import { useTranslation } from "react-i18next";

export const FooterSection = () => {
	const { t } = useTranslation();

	return (
		<>
			<p className="p-4 text-center md:text-left md:p-5">
				{t("For more information please contact us.")}
				<br />
				<b>{t("Tel")}:</b>{" "}
				<a href="tel:+992446000190">
					<u>(+992) 446 00 01 90</u>
				</a>
				<br />
				<b>{t("Email")}:</b>{" "}
				<a href="mailto:dushanbe@unicef.org">
					<u>dushanbe@unicef.org</u>
				</a>
				<br />
				<b>{t("Our address")}:</b>{" "}
				{t(
					"UNICEF Tajikistan. 5/1 Lohuti St, Dushanbe, Tajikistan, 734025."
				)}
			</p>
			<a
				className="text-3xl underline"
				href="https://www.unicef.org/tajikistan/"
				target="_blank"
				rel="noreferrer"
			>
				www.unicef.org
			</a>
		</>
	);
};
