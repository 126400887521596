import React, { createContext, useEffect, useState } from "react";
import { IImage } from "../types";
import { getImages } from "../api";

const initialContextValue: {
	images: IImage[];
	setImages: React.Dispatch<React.SetStateAction<IImage[]>>;
	loading: boolean;
} = {
	images: [],
	setImages: () => {},
	loading: false,
};

export const ImageContext = createContext(initialContextValue);

export const ImageContextProvider = ({ children }: React.PropsWithChildren) => {
	const [images, setImages] = useState<IImage[]>([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		getImages()
			.then((data: any) => {
				setImages(data);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<ImageContext.Provider value={{ images, setImages, loading }}>
			{children}
		</ImageContext.Provider>
	);
};
