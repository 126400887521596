import { Tween } from "react-gsap";
import { DELAY_START, DELAY_INCREASE } from "../../constants";
import { useTranslation } from "react-i18next";

export const NumbersSection = () => {
	const { t } = useTranslation();

	return (
		<section className="mt-5 md:mt-16">
			<div className="px-5 py-10 bg-contain md:bg-cover h-full text-white text-center bg-blue">
				<h2 className="text-3xl md:text-5xl uppercase">
					{t("Top-5 figures on nutrition challenges")}
				</h2>
			</div>
			<div className="bg-orange-500 flex flex-wrap py-10 md:py-20 gap-5 gap-y-10 md:gap-y-44 text-center md:text-left shadow-xl px-5 md:px-[200px] text-white">
				<Tween
					from={{
						opacity: 0,
						x: 100,
						duration: 1,
						delay: DELAY_START,
					}}
					to={{
						opacity: 1,
						x: 0,
						duration: 1,
						delay: DELAY_START,
					}}
				>
					<div className="flex items-center gap-x-10 text-white">
						<div>
							<h3 className="text-2xl uppercase text-yellow-300 mb-3">
								{t("stunting")}
							</h3>
							<p>
								{t(
									"The height of a stunted child is too short for their age. Stunting affects children’s physical and cognitive development and their future potential."
								)}
							</p>
						</div>
						<img
							className="hidden md:block"
							src="assets/18.svg"
							loading="lazy"
							width={120}
							alt="number-1"
						/>
						<img
							className="hidden md:block"
							src="assets/dots.svg"
							alt="dots"
							width={320}
						/>
					</div>
				</Tween>
				<Tween
					from={{
						opacity: 0,
						x: -100,
						duration: 1,
						delay: DELAY_START + DELAY_INCREASE,
					}}
					to={{
						opacity: 1,
						x: 0,
						duration: 1,
						delay: DELAY_START + DELAY_INCREASE,
					}}
				>
					<div className="flex items-center gap-x-10">
						<img
							className="hidden md:block"
							src="assets/dots.svg"
							alt="dots"
							width={320}
						/>
						<img
							className="hidden md:block"
							src="assets/6.svg"
							loading="lazy"
							width={120}
							alt="number-1"
						/>
						<div>
							<h3 className="text-2xl uppercase text-yellow-300 mb-3">
								{t("wasting")}
							</h3>
							<p>
								{t(
									"Wasted children are too thin for their height and face an elevated risk of mortality from common childhood illnesses such as pneumonia, requiring urgent interventions and care to save life."
								)}
							</p>
						</div>
					</div>
				</Tween>
				<Tween
					from={{
						opacity: 0,
						x: 100,
						duration: 1,
						delay: DELAY_START + DELAY_INCREASE * 2,
					}}
					to={{
						opacity: 1,
						x: 0,
						duration: 1,
						delay: DELAY_START + DELAY_INCREASE * 2,
					}}
				>
					<div className="flex items-center gap-x-10">
						<div>
							<h3 className="text-2xl uppercase text-yellow-300 mb-3">
								{t("anaemia")}
							</h3>
							<p>
								{t(
									"Anaemic children have lower immunity and compromised growth and development."
								)}
							</p>
						</div>
						<img
							className="hidden md:block"
							src="assets/42.svg"
							loading="lazy"
							width={120}
							alt="number-1"
						/>
						<img
							className="hidden md:block"
							src="assets/dots.svg"
							alt="dots"
							width={320}
						/>
					</div>
				</Tween>
				<Tween
					from={{
						opacity: 0,
						x: -100,
						duration: 1,
						delay: DELAY_START,
					}}
					to={{
						opacity: 1,
						x: 0,
						duration: 1,
						delay: DELAY_START,
					}}
				>
					<div className="flex items-center gap-x-10">
						<img
							className="hidden md:block"
							src="assets/dots.svg"
							alt="dots"
							width={320}
						/>
						<img
							className="hidden md:block"
							src="assets/37.svg"
							loading="lazy"
							width={120}
							alt="number-1"
						/>
						<div>
							<h3 className="text-2xl uppercase text-yellow-300 mb-3">
								{t("overweight")}
							</h3>
							<p>
								{t(
									"Women of reproductive age with overweight or obesity are at increased risk of non-communicable diseases like diabetes and experiencing unfavorable pregnancy complications."
								)}
							</p>
						</div>
					</div>
				</Tween>
				<Tween
					from={{
						opacity: 0,
						x: 100,
						duration: 1,
						delay: DELAY_START + DELAY_INCREASE,
					}}
					to={{
						opacity: 1,
						x: 0,
						duration: 1,
						delay: DELAY_START + DELAY_INCREASE,
					}}
				>
					<div className="flex items-center gap-x-10">
						<div>
							<h3 className="text-2xl uppercase text-yellow-300 mb-3">
								{t("anaemia")}
							</h3>
							<p>
								{t(
									"Anaemic women of reproductive age suffer from fatigue and dizziness and are more likely to experience negative pregnancy outcomes like premature birth and low birthweight babies."
								)}
							</p>
						</div>
						<img
							className="hidden md:block"
							src="assets/41.svg"
							loading="lazy"
							width={120}
							alt="number-1"
						/>
						<img
							className="hidden md:block"
							src="assets/dots.svg"
							alt="dots"
							width={320}
						/>
					</div>
				</Tween>
				<div className="col-span-12 mb-2 text-yellow-300 italic">
					{t(
						`In response UNICEF’s Nutrition programming is focused on prevention first, and providing early and quality treatment as soon as it’s required.`
					)}
				</div>
			</div>
		</section>
	);
};
