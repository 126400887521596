import React from "react";
import { RippleButton, FooterSection } from "../";
import { useTranslation } from "react-i18next";

export const Footer = () => {
	const handleClick = () => {
		window.open("https://www.unicef.org/tajikistan/nutrition", "_blank");
	};
	const { t } = useTranslation();

	return (
		<footer className="flex flex-col center items-center justify-center my-10">
			<RippleButton
				className="bg-red-600 p-5 text-white font-bold text-xl md:text-2xl uppercase relative group"
				title={t("Partner with us")}
				onClick={handleClick}
			></RippleButton>
			{/* <p className="md:w-[900px] my-5 text-center text-lg md:text-2xl w-full p-2">
				{t(
					"and together we can safeguard the progress that has been made, and build better, more resilient nutrition programmes to achieve our commitments to children – helping them to survive and thrive."
				)}
			</p> */}
			<hr className="h-px my-8 bg-black w-[90%] md:w-2/3 border-0 dark:bg-gray-700" />
			<FooterSection />
			<div className="font-bold text-4xl mt-10">#NutritionNow</div>
			<hr className="h-px my-8 bg-black w-[90%] md:w-2/3 border-0 dark:bg-gray-700" />
			<div className="flex flex-wrap justify-center items-center gap-10">
				<img
					src="assets/footer2.png"
					alt="usaid logo"
					loading="lazy"
					width="200"
				/>
				<img
					src="assets/footer1.png"
					alt="unicef logo"
					loading="lazy"
					width="300"
				/>
			</div>
			{/* <img src="assets/footer-images.jpg" alt="footer-partners" /> */}
		</footer>
	);
};
