// methods for getting images from the server
// and uploading images to the server

import { fetchData } from "./helper";
import { IImage } from "../types";
import { API_URL } from "../constants";

export const getImages = async (): Promise<IImage[]> => {
	const apiUrl = `${API_URL}/images`;
	return fetchData<IImage[]>(apiUrl);
};
