export const VideoSection = () => (
	<section className="px-4 lg:container mx-auto my-10 lg:my-40 shadow-2xl bg-blue p-4">
		{/* <video controls width="100%" height="100">
			<source src="assets/un-nut-video.mp4" />
			Your browser does not support the video tag.
		</video> */}
		<iframe
			className="h-64 lg:h-[500px]"
			width="100%"
			height="500"
			src="https://www.youtube.com/embed/yIZst_p4k7A?si=vjWsbIigw9dNiC6W"
			title="YouTube video player"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			allowFullScreen
		></iframe>
	</section>
);
